.easyTimer {
    font-size: 30px;
}
.stockdetails{
    float:right;
}
.addnewtask{
    float:right;
    padding: 40px;
    margin-right: -80px;
}