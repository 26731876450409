.mydayaddtask{
    float: right;
    margin-top: 7px;
    margin-right: 10px;
}
.addpurchasestock{
  float: right;
  margin-top: 7px;
  margin-right: 5px;
}

.addsalesstock{
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.searchForm{
  position: absolute;
  right: 20px;
}

.searchBox {
  outline: none;
  background-color: red;
}

.searchBox {
  appearance: textfield;
  box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}
.searchBox::-webkit-search-decoration,
.searchBox::-webkit-search-cancel-button {
  display: none;
}

.searchBox{
  background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px #ccc;
  padding: 9px 10px 9px 32px;
  width: 55px;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.searchBox:focus {
  width: 130px;
  background-color: #fff;
  border-color: #66cc75;

  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

.searchBox:-moz-placeholder {
  color: #999;
}
.searchBox::-webkit-input-placeholder {
  color: #999;
}

.searchForm .searchBox {
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
}

.searchForm .searchBox:hover {
  background-color: #fff;
}

.searchForm .searchBox:focus {
  width: 130px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
}

.searchForm .searchBox:-moz-placeholder {
  color: transparent;
}

.searchForm .searchBox::-webkit-input-placeholder {
  color: transparent;
}