.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.x {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 10px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 20px;
  background: #002933;
  border-radius: 15px;
  border: none;
  color: #fff;
}

.button {
  text-align: right;
  margin: 5px;
}

@media (max-width: 992px) 
{ 
  .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
}

@media (max-width: 767.98px){
  .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .Scroll-Content {
    width: 100%; 
    height: 400px; 
    overflow-Y: auto;
  }
  }

@media (max-width: 520px) {
  .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
}
#buttonsave{
 margin-top: 30px;
}