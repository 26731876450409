.iconEdit{
    border: none;
    border-radius: 15px;
    padding: 5px;
    margin: 3px;
    align-self: center;
  }
  
  .text-center{
      text-align: center;
  }