.partiallypaid {
  width: 100px;
  height: 30px;
  background: #008ae6;
  color: white;
  border-radius: 100px / 60px;
}
.paid {
  width: 100px;
  height: 30px;
  background: #009933;
  color: white;
  border-radius: 100px / 60px;
}
.Unpaid {
  width: 100px;
  height: 30px;
  background: #ff0000;
  color: white;
  border-radius: 100px / 60px;
}
.Cancelled{
  width: 100px;
  height: 30px;
  background: #f0801f;
  color: white;
  border-radius: 100px / 60px;
}