.charts td {
  padding-left: 150px;
}
.Dropdownbutton {
  float: right;
  margin-top: 10px;
  margin-left: 790px;
}
.defaultCheckbox {
  width: 30px;
  height: 30px;
  float: right;
  margin-top: -30px;
  margin-right: 430px;
}
.invoicegenerate {
  float: right;
  margin-top: 18px;
  margin-right: 35px;
}
@media (max-width: 1200px) {
  .defaultCheckbox {
    width: 30px;
    height: 30px;
    margin-top: -30px;
    margin-right: 70px;
  }
}
@media (max-width: 1200px) {
  .showall {
    margin-top: 20px;
    margin-right: 150px;
  }
}

@media (max-width: 1400px) {
  .Dropdownbutton {
    float: right;
    margin-top: -48px;
    margin-right: -165px;
  }
}

.single-travel-boxes {
  margin-top: 0px;
  background: #000;
}
.tab-para p {
  font-size: 24px;
}

.desc-tabs {
  border: transparent;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.desc-tabs .nav-tabs {
  display: inline-block;
  border-right: 1px solid #fff;
}

.desc-tabs .nav-tabs > li.active > a {
  color: #fff;
  background: #00d8ff;
  cursor: default;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.desc-tabs .nav-tabs > li.active > a:focus,
.desc-tabs .nav-tabs > li.active > a:hover {
  color: #565a5c;
  text-decoration: none;
}
.desc-tabs .nav-tabs > li > a {
  color: #fff;
  background: #00d8ff;
  cursor: default;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  padding: 17px 40px 17px 46px;
  border: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.single-travel-boxes .nav > li > a:focus,
.single-travel-boxes .nav > li > a:hover {
  color: #565a5c;
  background: #fff;
  border: 0;
  text-decoration: none;
}
.desc-tabs .nav-tabs > li > a > i {
  padding-right: 6px;
}

.tab-para {
  padding: 43px 42px 24px;
}
.single-tab-select-box h2 {
  color: #565a5c;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  margin-bottom: 17px;
}
.travel-select-icon {
  position: relative;
}
.travel-select-icon:after {
  position: absolute;
  content: "\f107";
  right: 0px;
  top: 0;
  font-size: 18px;
  color: #aaa;
  background: #f9f9f9;
  font-family: "fontAwesome";
  pointer-events: none;
  border: 1px solid #d7d7d7;
  border-bottom: transparent;
  padding: 10px;
}

.travel-check-icon {
  position: relative;
}
.travel-check-icon:after {
  position: absolute;
  content: "\f073";
  right: 0px;
  top: 0;
  font-size: 18px;
  color: #aaa;
  background: #f9f9f9;
  font-family: "fontAwesome";
  pointer-events: none;
  border: 1px solid #d7d7d7;
  border-bottom: transparent;
  padding: 10px 8px;
}

.travel-select-icon select,
.travel-check-icon input {
  color: #aaa;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  height: 25px;
}

.travel-select-icon .form-control,
.travel-check-icon .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0 !important;
  box-shadow: none;
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  margin-bottom: 20px;
}

.about-btn {
  margin-top: 39px;
  letter-spacing: 1.2px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.about-view {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00d8ff;
  border: 1px solid #00d8ff;
  width: 200px;
  height: 60px;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(14, 15, 18, 0.2);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.about-btn:hover .about-view {
  color: #fff;
  background: #00d8d5;
  box-shadow: 0 5px 20px rgba(14, 15, 18, 0.7);
  border: 1px solid #00d8d5;
}

.about-view.travel-btn {
  width: 180px;
  margin-right: 15px;
}
