.worktimeassign{
    margin-top: 5px;
}
.text-center{
    text-align: center;
}

.datepiker {
    float: right;
    margin-top: -50px;
    margin-right: 600px; 
}
