.create {
    border: none;
    float: right;
    background-color: #002933;
    padding: 10px;
    margin: 10px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    border-radius: 10px;
    font-size: 15px;
    transition: 0.5s;
}
  
.create:hover {
    background-color: #002933;
    padding: 11px;
    margin: 11px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}
.editRow-sub-title-comments{
    width: 310%;
    height: 35px;
    padding: 1px 1px;
    margin: 1px;
    display: inline-block;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: left;
}
.editRowModel-Date{
    width: 100%;
    padding: 1px 1px;
    margin: 1px;
    display: inline-block;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    text-align: left;
}