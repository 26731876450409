#download{
    float: right;
}
.Export{
    border: none;
    float: right;
    background-color: #002933;
    padding: 10px;
    margin: 10px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    border-radius: 10px;
    font-size: 15px;
    transition: 0.5s;
}
.Export:hover{
    background-color: #002933;
    padding: 11px;
    margin: 11px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

.addRowBtn{
    border: none;
    float: right;
    background-color:#5e72e4;
    color: #fff;
    border-radius: 10px;
    font-size: 15px;
}